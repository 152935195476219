<template>
  <div class="topicList">
    <div class="topicList_item" v-for="(item, index) in topicList" :key="index">
      <div class="topicList_item_left" @click="openTopic(item.id)">
        <img :src="item.cover" />
        <div class="topicList_item_leftNum">共{{ item.num }}个作品</div>
      </div>
      <div class="topicList_item_right">
        <div class="topicList_item_rightTitle">{{ item.title }}</div>
        <div class="topicList_item_rightInfo">{{ item.info }}</div>
        <div class="topicList_item_rightMore" @click="openTopic(item.id)">
          <div>
            <img :src="item.item_cover" />
          </div>
          <div>查看更多</div>
        </div>
        <div class="topicList_item_rightFooter"></div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="10"
        :total="count"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topicList: [],
      currentPage: 1,
      count: 0,
    };
  },
  created() {
    this.getTopicList();
  },
  methods: {
    getTopicList() {
      this.$axios({
        url: this.$api + "/auth/ppt/album/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          title: "",
          page: this.currentPage,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.topicList = res.data.data.res;
          this.count = res.data.data.count;
        }
      });
    },
    openTopic(e) {
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: e,
        },
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>

<style scoped>
.topicList {
  width: 1200px;
  font-size: 14px;
  margin: 10px auto;
}
.topicList_item {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.topicList_item_left {
  width: 60%;
  height: 400px;
  position: relative;
}
.topicList_item_left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.topicList_item_leftNum {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 30px;
  padding: 5px 10px;
  background-color: #ffffffa2;
}
.topicList_item_right {
  border-radius: 10px;
  padding: 1% 1% 0;
  box-sizing: border-box;
  width: 38%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #e3e3e3;
}
.topicList_item_rightTitle {
  font-size: 16px;
  font-weight: 600;
}
.topicList_item_rightInfo {
  color: #666;
}
.topicList_item_rightMore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.topicList_item_rightMore > div:nth-of-type(1) {
  width: 120px;
  height: 80px;
}
.topicList_item_rightMore > div:nth-of-type(1) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.topicList_item_rightMore > div:nth-of-type(2) {
  width: 120px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e5e5e5;
}
.topicList_item_rightFooter {
  cursor: pointer;
  color: #666;
  padding: 10px 0;
  border-top: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.topicList_item_rightFooter i {
  font-size: 14px;
  margin: 0 10px;
}

.page {
  padding: 3% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 2000px) and (max-width: 2500px) {
  .topicList {
    width: 80%;
  }
  .topicList_item_left {
    height: 40vh;
  }
  .topicList_item_rightMore > div:nth-of-type(1) {
    width: 120px;
    height: 80px;
  }
  .topicList_item_rightMore > div:nth-of-type(2) {
    width: 120px;
    height: 80px;
  }
  .topicList_item_rightTitle{
    font-size: 24px;
  }
  .topicList_item_rightInfo{
    font-size: 18px;
  }
  .topicList_item_leftNum{
    font-size: 14px;
  }
}
@media screen and (min-width: 4000px) and (max-width: 4500px) {
  .topicList {
    width: 80%;
  }
  .topicList_item_left {
    height: 40vh;
  }
  .topicList_item_rightMore > div:nth-of-type(1) {
    width: 240px;
    height: 160px;
  }
  .topicList_item_rightMore > div:nth-of-type(2) {
    width: 240px;
    height: 160px;
  }
  .topicList_item_rightTitle{
    font-size: 48px;
  }
  .topicList_item_rightInfo{
    font-size: 36px;
  }
  .topicList_item_leftNum{
    font-size: 32px;
  }
}
</style>